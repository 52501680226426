import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const Fizjoterapia = () => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO
        seoTitle="Fizjoterapeuta Kraków - fizjoterapia, terapia manualna, kinezyterapia"
        description="Nasz gabinet fizjoterapii w Krakowie to zespół najlepszych specjalistów. Fizjoterapia obejmuje: kinezyterapię, terapię manualną i fizykoterapię."
      />

      <GlobalTop title="Fizjoterapeuta Kraków" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Czym zajmuje się
                <strong>Fizjoterapeuta?</strong>
              </h2>
              <p>
                Fizjoterapia jest nauką medyczną obejmującą różne metody
                leczenia naturalnego, skupiającego się na utrzymaniu,
                przywracaniu i rozwijaniu sprawności ruchowej.
              </p>
              <p>
                <strong>
                  Zajmując się leczeniem energią fizyczną, fizjoterapia
                  obejmuje:
                </strong>
              </p>
              <ul>
                <li>kinezyterapię, czyli ruch i różne formy ćwiczeń,</li>
                <li>terapię manualną, w tym masaż, </li>
                <li>
                  fizykoterapię, czyli zabiegi leczące poprzez bodźce fizykalne.
                </li>
              </ul>
              <p>
                Fizjoterapia pomaga w leczeniu schorzeń wielu układów. Poza
                układem mięśniowo-szkieletowym jest to układ nerwowy, układ
                sercowo-naczyniowy, układ oddechowy.
              </p>
              <p>
                Ze względu na rolę, jaką w ludzkim ciele pełnią stopy, są one
                jedną z ważniejszych partii, na których skupia się uwaga
                fizjoterapeuty. Wizyty w gabinecie fizjoterapeutycznym
                niejednokrotnie rozpoczynają się od specjalistycznej diagnostyki
                stóp. To od stóp zależy bowiem stan kolan, ścięgien i więzadeł
                oraz kręgosłupa. Co więcej, objawy odczuwane w stopach mogą
                sygnalizować problemy występujące w wielu innych partiach ciała.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer-fizjoteriapia-image-1.png")}
                srcSet={require("../../assets/images/offer-fizjoteriapia-image-1@2x.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer-fizjoteriapia-image-2.png")}
                srcSet={require("../../assets/images/offer-fizjoteriapia-image-2@2x.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <p>
                Podologia i fizjoterapia są nierozłączne, dlatego podolog często
                potrzebuje opinii fizjoterapeuty i współpracuje z nim w celu
                udzielenia pacjentowi skutecznej pomocy. Zaawansowane przypadki
                dolegliwości podologicznych mogą też wymagać wsparcia
                fizjoterapeutycznego, natomiast fizjoterapia może stać się
                efektywniejsza dzięki pomocy zabiegów podologicznych.
              </p>
              <p>
                <strong>
                  Elementy zarówno podologii, jak i fizjoterapii są niezbędne w
                  działaniach takich jak np.:
                </strong>
              </p>
              <ul>
                <li>diagnostyka komputerowa wad stóp,</li>
                <li>przygotowania ortez indywidualnych,</li>
                <li>rehabilitacja podologiczna,</li>
                <li>terapia podologiczna.</li>
                <li>
                  Fizjoterapia ma też istotne znaczenie w leczeniu schorzeń
                  typowo podologicznych, m. in..:
                </li>
                <li>płaskostopia,</li>
                <li>halluxów,</li>
                <li>ostrogi piętowej,</li>
                <li>nawracającego skręcenie stawu skokowego.</li>
              </ul>
              <p>
                Zaniedbanie tych dolegliwości może wymagać poważniejszego
                leczenia łączącego się z długotrwałą rehabilitacją, dlatego
                obserwując u siebie niepokojące objawy, warto jak najszybciej
                zdecydować się na konsultację u podologa. Szybko wyleczone
                problemy w obrębie stóp pozwalają wykluczyć inne schorzenia.
                Potwierdzenie chorób dotykających inne części ciała pozwala
                natomiast rozpocząć leczenie dolegliwości u źródła.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default Fizjoterapia
