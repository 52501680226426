import React from "react"
import { Link } from "gatsby"

const GlobalTop = ({
  title,
  post,
  offer,
  podologia,
  chirurgiaPalca,
  opinion,
  kariera,
  light,
  isTitleVisible,
}) => {
  return (
    <section className={`global-top${light ? " global-top--light" : ""}`}>
      <div
        className={`global-top__breadcrumb${
          light ? " global-top__breadcrumb--light" : ""
        }`}
      >
        <Link to="/">Strona główna</Link>
        {post && <Link to="/blog/">Blog</Link>}
        {offer && <Link to="/oferta/">Oferta</Link>}
        {podologia && <Link to="/oferta/podologia/">Podologia</Link>}
        {chirurgiaPalca && (
          <Link to="/oferta/chirurgia-palca/">Chirurgia palca</Link>
        )}
        {opinion && <Link to="/opinie-klientow/">Opinie klientów</Link>}
        {kariera && <Link to="/kariera/">Kariera</Link>}
        <span>{title}</span>
      </div>
      <h1>{kariera && !isTitleVisible ? "Kariera" : title}</h1>
    </section>
  )
}

export default GlobalTop
